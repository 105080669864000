<template>
  <div class="item-wrap">
    <!-- 题目 -->
    <div class="head-wrap">
      <div class="title-wrap van-multi-ellipsis--l3" @click="clickHandle">
        <div class="title">
          <img v-if="detail.is_new" src="../../assets/new.png" />
          <span v-html="detail.title"></span>
        </div>
      </div>
      <div class="select-wrap">
        <Icon class-prefix="iconfont" :name="collectedIcon" size="20" :color="collectedCOlor"  @click="collectHandle"/>
      </div>
    </div>
    <!-- 详情字段 article -->
    <div @click="clickHandle" v-if="detail.article_data_type === 'article'">
      <div class="author van-ellipsis">
        <span>作者：</span>
        <span class="author-item" v-for="item in detail.other_info.authors" :key="item">{{ item }}</span>
      </div>
      <div class="source">
        <span>来源期刊：</span>
        <span>{{ detail.journal }}</span>
        <span class="tag" v-if="detail.journal_type_info !== ''">{{ detail.journal_type_info }}</span>
      </div>
      <div class="footer">
        <div class="indicator" v-if="detail.impact_factor">
          <span>影响因子：</span>
          <span>{{ detail.impact_factor }}</span>
        </div>
        <div class="time">
          <span>发表时间：</span>
          <span>{{ detail.publish_year }}</span>
        </div>
      </div>
    </div>
    <!-- 详情字段 guide -->
    <div v-else @click="clickHandle">
      <div class="source van-multi-ellipsis--l2">
        <span>发布机构：</span>
        <span>{{ detail.institute }}</span>
      </div>
      <div class="source">
        <span>发表时间：</span>
        <span>{{ detail.publish_year }}</span>
      </div>
    </div>
    <!-- 收藏时间 -->
    <div class="collect-time-wrap" v-if="detail.is_collected">
      <span>收藏时间：</span>
      <span>{{ detail.is_collection.collection_date }}</span>
    </div>
    <!-- 链接展示 -->
    <div class="url_wrap" v-if="detail.article_data_type !== 'guide'">
      <span class="pubmed" v-if="detail.source === 'pubmed'" @click.stop="handleUrlClick">Pubmed</span>
      <span class="zhiwang" v-if="detail.source === '知网'" @click.stop="handleUrlClick">中国知网</span>
      <span class="wanfang" v-if="detail.source === '万方医学'" @click.stop="handleUrlClick">万方医学</span>
      <span class="zhyxqk" v-if="detail.source === '中华医学期刊网'" @click.stop="handleUrlClick">中华医学期刊网</span>
    </div>
    <div v-if="detail.article_data_type === 'guide'">
      <div class="guide-wrap">
        <div class="name">原文下载:</div>
        <span class="zhyxqk" @click.stop="handleDownloadUrl">下载链接</span>
      </div>
      <div class="guide-wrap">
        <div class="name">内容解读:</div>
        <div
          v-for="(c, index) in detail.other_info.interpreter"
          :key="index"
          @click="handleClickContent(c)"
          :class="{'ddzj': c.name === '大道至检e课堂', 'ymt': c.name === '医脉通', 'yw': c.name === '原文解读'}"
        >{{ c.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Toast } from 'vant'
import { computed, ref } from 'vue'
import { collectApi, cancelCollectApi } from '@/api/'
export default {
  props: {
    detail: {
      type: Object
      // default: () => ({})
    },
    is_collected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  },
  setup (props, { emit }) {
    const isCollect = ref(props.detail.is_collection.is_collection)
    const deleteId = ref(null)
    const clickHandle = () => {
      emit('on-click', props.detail)
    }

    const collectedIcon = computed(() => {
      if (isCollect.value) {
        return 'yishoucang'
      } else {
        return 'shoucang1'
      }
    })

    const collectedCOlor = computed(() => {
      if (isCollect.value) {
        return '#0C86FE'
      } else {
        return '#B9C6D3'
      }
    })

    const collectHandle = () => {
      if (isCollect.value) {
        cancelCollectApi(deleteId.value || props.detail.is_collection.pk, {
          is_collected: false
        }).then(res => {
          if (res.code === 200) {
            isCollect.value = false
            Toast('取消收藏')
          }
        })
      } else {
        collectApi({
          article_id: props.detail.article_id ? props.detail.article_id : props.detail.id
        }).then(res => {
          if (res.code === 200) {
            deleteId.value = res.data.data.id
            isCollect.value = true
            Toast('收藏成功')
          }
        })
      }
    }

    const handleUrlClick = () => {
      if (props.detail.source_url) {
        location.href = props.detail.source_url
      }
    }

    const handleDownloadUrl = () => {
      if (props.detail.source_url !== '') {
        location.href = props.detail.source_url
      }
    }

    const handleClickContent = (item) => {
      if (item.url) {
        location.href = item.url
      }
    }

    return {
      clickHandle,
      collectedIcon,
      collectedCOlor,
      collectHandle,
      handleUrlClick,
      handleDownloadUrl,
      handleClickContent
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrap {
  padding: 20px 16px;
  background: #fff;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(.5);
  }
  .head-wrap {
    display: flex;
    justify-content: space-between;
    .title-wrap {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      .title {
        font-size: 16px;
        color: #333333;
        line-height: 18px;
        word-break: break-all;
        & > img {
          width: 32px;
          height: 14px;
          object-fit: contain;
          margin-right: 8px;
        }
      }
    }
    .select-wrap {
      margin-left: 37px;
    }
  }
  .author {
    margin-top: 24px;
    font-size: 12px;
    color: #999999;
    line-height: 14px;
    .author-item {
      margin-right: 4px;
    }
  }
  .collect-time-wrap {
    font-size: 12px;
    color: #999999;
    line-height: 14px;
    margin-top: 10px;
  }
  .source {
    font-size: 12px;
    color: #999999;
    line-height: 14px;
    margin-top: 10px;
    .tag {
      padding: 2px 6px;
      background: #EBF5FF;
      border-radius: 2px;
      color: #0C86FE;
      margin-left: 14px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #999999;
    line-height: 14px;
    margin-top: 10px;
  }
  .url_wrap {
    color: #fff;
    font-size: 12px;
    margin-top: 12px;
    .pubmed {
      padding: 5px 8px;
      background: linear-gradient(135deg, #70E2F2 0%, #58C2DD 100%);
      box-shadow: 0px 2px 6px 0px #61C7D5;
      border-radius: 4px;
      cursor: pointer;
    }
    .zhiwang {
      padding: 5px 8px;
      background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
      box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
      border-radius: 4px;
    }
    .wanfang {
      padding: 5px 8px;
      background: linear-gradient(135deg, #C0B1FD 0%, #A587F3 100%);
      box-shadow: 0px 2px 6px 0px #B7A6F8;
      border-radius: 4px;
    }

    .zhyxqk {
      padding: 5px 8px;
      background: #1C4C61;
      box-shadow: 0px 2px 6px 0px #1C4C61;
      border-radius: 4px;
    }
  }

  .guide-wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .name {
      font-size: 12px;
      color: #999;
      margin-right: 6px;
    }
    .zhyxqk {
      padding: 0 8px;
      background: linear-gradient(135deg,#8bcaff,#3ba5fd);
      box-shadow: 0 2px 6px 0 rgba(59,165,253,.5);
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
    }

    .ddzj {
      padding: 0 8px;
      background: linear-gradient(135deg, #70E2F2 0%, #58C2DD 100%);
      box-shadow: 0px 2px 6px 0px #61C7D5;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
    }
    .ymt {
      padding: 0 8px;
      background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
      box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
    }
    .yw {
      padding: 0 8px;
      background: linear-gradient(135deg, #C0B1FD 0%, #A587F3 100%);
      box-shadow: 0px 2px 6px 0px #B7A6F8;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
    }
  }
}
</style>
