<template>
  <div class="back-top" v-if="isShow" @click="backTop">
    <Icon name="back-top" size="0.533333rem" color="#fff" />
  </div>
</template>

<script>
import { Icon } from 'vant'
import { onMounted, onUnmounted, ref, watch } from 'vue'
export default {
  props: ['el'],
  components: {
    Icon
  },
  setup (props) {
    const isShow = ref(false)
    const scrollTop = ref(0)

    onMounted(() => {
      window.addEventListener('scroll', scrollToTop, false)
    })

    onUnmounted(() => {
      if (props.el) {
        props.el.removeEventListener(
          'scroll',
          () => {
            isShow.value = false
          },
          false
        )
      }
      window.removeEventListener(
        'scroll',
        () => {
          isShow.value = false
        },
        false
      )
    })

    watch(
      () => props.el,
      val => {
        if (val) {
          val.addEventListener('scroll', scrollToTop, false)
          window.removeEventListener(
            'scroll',
            () => {
              isShow.value = false
            },
            false
          )
        }
      }
    )

    function backTop () {
      const timer = setInterval(() => {
        const ispeed = Math.floor(-scrollTop.value / 5)
        if (props.el) {
          // eslint-disable-next-line vue/no-mutating-props
          props.el.scrollTop = scrollTop.value + ispeed
        } else {
          document.documentElement.scrollTop = document.body.scrollTop =
            scrollTop.value + ispeed
        }
        if (scrollTop.value === 0) {
          clearInterval(timer)
        }
      }, 16)
    }

    function scrollToTop () {
      if (props.el) {
        scrollTop.value = props.el.scrollTop
      } else {
        scrollTop.value =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      }
      if (scrollTop.value > 120) {
        isShow.value = true
      } else {
        isShow.value = false
      }
    }

    return {
      isShow,
      backTop
    }
  }
}
</script>

<style lang="less" scoped>
.back-top {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 3px 0 rgba(36, 42, 68, 0.3);
  position: fixed;
  bottom: 90px;
  right: 15px;
  background: #748094;
}
</style>
