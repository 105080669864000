<template>
  <div class="my-empty">
    <van-empty description="暂无数据"></van-empty>
  </div>
</template>

<script>
import { Empty } from 'vant'
export default {
  components: {
    'van-empty': Empty
  }
}
</script>

<style lang="less" scoped>
.mt-empty {
  position:fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white
}
</style>
