<template>
  <div class="list-filter-wrap">
    <div class="total">共{{ total }}条数据</div>
    <div class="select-wrap">
      <div class="sort-wrap" id="mydropDown">
        <DropdownMenu active-color="#333">
          <DropdownItem ref="dropdpwnItem">
            <template #title>
              <div class="custom-name">
                <span>{{ state.current.text }}</span>
                <Sort :type="state.current.type" />
              </div>
            </template>
            <Cell v-for="item in sortOpt" :key="item.value" @click="clickHandle(item)">
              <div class="custom-name">
                <span>{{ item.text }}</span>
                <Sort :type="item.type" />
              </div>
            </Cell>
          </DropdownItem>
        </DropdownMenu>
      </div>
      <div class="full-text" :class="{'full-text-active': fullTextShow}" @click="handleClickFullText"><span class="name">原文</span></div>
      <div class="dashboard" @click="dashboardHandle" v-if="show">
        <Icon class-prefix="iconfont" name="shuju" size="16" color="#0C86FE" />
        <span class="name">统计模式</span>
      </div>
    </div>
  </div>
</template>

<script>
import { DropdownMenu, DropdownItem, Cell, Icon } from 'vant'
import Sort from '@/components/sort/'
import { reactive, ref } from 'vue'
import { deepCopy } from '@/utils/'
export default {
  components: {
    DropdownMenu,
    DropdownItem,
    Sort,
    Cell,
    Icon
  },
  props: {
    total: {
      type: Number,
      default: 5623
    },
    show: {
      type: Boolean,
      default: true
    },
    fullTextShow: {
      type: Boolean,
      default: false
    },
    sortOpt: {
      type: Array,
      default: () => ([
        { text: '相关度', value: 0, key: 'relevance_asc', type: 'desc' },
        { text: '发表时间', value: 1, key: 'publish_year_asc', type: 'asc' },
        { text: '发表时间', value: 0, key: 'publish_year_asc', type: 'desc' },
        { text: '影响因子', value: 1, key: 'impact_factor_asc', type: 'asc' },
        { text: '影响因子', value: 0, key: 'impact_factor_asc', type: 'desc' }
      ])
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      current: null
    })
    const dropdpwnItem = ref(null)
    const option = deepCopy(props.sortOpt)
    state.current = option[0]

    const clickHandle = (item) => {
      state.current = item
      dropdpwnItem.value.toggle()
      emit('on-change', item)
    }

    const dashboardHandle = () => {
      emit('on-click')
    }

    const handleClickFullText = () => {
      emit('on-full-text')
    }

    return {
      state,
      dropdpwnItem,
      clickHandle,
      dashboardHandle,
      handleClickFullText
    }
  }
}
</script>

<style lang="less" scoped>
.list-filter-wrap {
  height: 48px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 16px;
  .total {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }
  .select-wrap {
    display: flex;
    align-items: center;
    .sort-wrap {
      &:deep(.van-dropdown-menu__bar) {
        box-shadow: none;
      }
      &:deep(.van-dropdown-menu__title::after) {
        border-color: transparent;
      }
    }
    .custom-name {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 8px;
      }
    }
    .dashboard {
      width: 94px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      box-sizing: border-box;
      margin-left: 8px;
      .name {
        font-size: 14px;
        font-weight: 400;
        color: #0C86FE;
        line-height: 14px;
        margin-left: 7px;
      }
    }

    .full-text {
      padding: 0 10px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      color: #0C86FE;
    }

    .full-text-active {
      background: #0C86FE;
      color: #FFFFFF;
    }
  }
}
</style>
